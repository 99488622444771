import React from "react";

function Orcamento(){
  return (
    <section id='orcamento'>

      <div className='container'>
        <div className='text-center'>
          <h5>A MICRO-F É ESPECIALIZADA EM AUTOMAÇÃO COMERCIAL</h5>
          <h3>COM GRANDE EXPERTISE EM SOLUÇÕES PARA</h3>
          <h3 id="bold">LOJAS DE VAREJO EM GERAL, FARMÁCIAS E RESTAURANTES!</h3>
        </div>
        <div className="container text-center mt-4">
            <a href="https://api.whatsapp.com/send?phone=556234346522" target="_blank" className="btn btn-primary btn-lg">Solicite um orçamento agora!</a>
        </div>        
      </div>

    </section>
  )
}

export default Orcamento;