import React from "react";

function Contato(){
  return (
    <section id='contato' className='mt-5'>

      <div className='container'>
        <h3 className='text-center'>ENTRE EM CONTATO</h3>
        <p className="mt-0 text-center">A melhor solução em automação comercial você encontra somente na Micro-F!<br/>Solicite agora!</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5 className="mt-2 text-center color-title-escolha"><i className="fa-solid fa-building fa-3x"></i></h5>
            <h5 className="mt-2 text-center color-title-escolha">ESCRITÓRIO CENTRAL</h5>
            <p className="mt-2 text-center">São Bernardo do Campo<br/><i className="fa-solid fa-phone"></i> (11) 2355-9611</p>
          </div>

          <div className="col-4">
            <h5 className="mt-2 text-center color-title-escolha"><i className="fa-solid fa-square-phone fa-3x"></i></h5>
            <h5 className="mt-2 text-center color-title-escolha">ATENDIMENTO AO CLIENTE</h5>
            <p className="text-center">Você pode entrar em contato conosco através de nossos telefones:
                <br/><i className="fa-solid fa-phone"></i> (11) 2355-9611 São Paulo
                <br/><i className="fa-solid fa-phone"></i> (62) 3434-6522 Goiânia
            </p>
            <p className="text-center">ou através de nossos e-mails:
                <br/><i className="fa-solid fa-envelope"></i> financeiro@micro-f.com.br
                <br/><i className="fa-solid fa-envelope"></i> financeiromicrof@gmail.com
            </p>
          </div>
          
          <div className="col-4">
            <h5 className="mt-2 text-center color-title-escolha"><i className="fa-brands fa-whatsapp fa-3x"></i></h5>
            <h5 className="mt-2 text-center color-title-escolha">ATENDIMENTO WHATSAPP</h5>
            <p className="mt-2 text-center">Nosso suporte técnico também pode ser feito através do WhatsApp Business:
                <br/><a className='no-decor-link' href='https://wa.me/551123559611' target="_blank">(11) 2355-9611</a>
            </p>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Contato;