import React from 'react';
import api from '../services/api';
import Note from '../assets/mockup-note.png';
import { useState } from 'react';
import swal from 'sweetalert';

function Reset(props){

    const [serie, setSerie] = useState('')
    const [compl, setCompl] = useState('')

    const processaReset = (event)=>{
        event.preventDefault();

        if(serie === '' || compl === ''){
            swal('Atenção !', 'Todos os campos devem ser preenchidos.', 'error');
            return;
        }

        api.get(`reset/${serie}/${compl}`)
        .then((response) => {

            let reset = response.data

            if(reset.length === 0) {
                swal("Número de série ou complemento inválido", "", "error");
            } else {
                reset.map((item) => {
                    return swal({title: `${item.NOME}`,
                                buttons: { ok: "Fechar",},
                                icon: "success",
                                text: `Número de Série: ${item.SERIE} \nAté o dia: ${item.VENCIMENTO} \nDias: ${item.DIAS} \nCódigo: ${item.CODIGO}`})
                })
            }

            setSerie('')
            setCompl('')

        })
        .catch((err) => {
            swal("Falha ao acessar o servidor", "", "error");
            console.log(err);
        })
    }

    return (
        <section id='reset'>
            <div className='container mt-4 mb-4'>
                <div className='text-center'>
                    <h3 className='mb-0'>PEGUE AQUI SEU RESET</h3>
                    <p>ou com um de nossos atendentes On-line</p>
                </div>            
                <div className='row mt-5'>
                    <div className='col-sm-6 text-end'>
                        <img src={Note} height='300px' alt='Mockup Notebook' />
                    </div>
                    <div className='col-sm-6 d-flex text-start align-items-center'>
                        <form className='form-login' onSubmit={processaReset}>
                            <div className='form-floating'>
                                <input 
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Número de Série"
                                    onChange={(e) => setSerie(e.target.value)}
                                    value={serie}
                                />
                                <label htmlFor='floatingInput'>Número de Série</label>
                            </div>
                            <div className='form-floating'>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Complemento"
                                    onChange={(e) => setCompl(e.target.value)}
                                    value={compl}
                                />
                                <label htmlFor='floatingInput'>Complemento</label>  
                            </div>

                            <input className="w-100 btn btn-lg btn-dark mt-2" type="submit" value="Enviar" />
                        </form>
                    </div>
                </div>
            </div>
        </section>
        );
    };

export default Reset;