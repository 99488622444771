import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';


function FormMail(props){

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [mensagem, setMensagem] = useState('')

    function sendEmail(event){
        event.preventDefault();

        if(nome === '' || email === '' || mensagem === ''){
            swal('Atenção !', 'Todos os campos devem ser preenchidos.', 'error');
            return;
        }

        const templateParams = {
            from_name: nome,
            email: email,
            mensagem: mensagem
        }

        emailjs.send('service_rjjo57d', 'template_u2usgog', templateParams, 'ML2piXxPT9hTEmoWY')
        .then((response) => {
            swal("Sucesso !", "Mensagem de e-mail enviada", "success");
            setNome('')
            setEmail('')
            setMensagem('')
        }, (err) => {
            console.log('Erro: ', err)
        })

    };

    return (
        <section id='form-email'>
            <div className="container mt-4 mb-5">
            <div className='text-center'>
                <h3 className='mb-0'>TEMOS A MELHOR SOLUÇÃO PARA SEU COMÉRCIO</h3>
                <p><b>VAMOS CONVERSAR SOBRE AUTOMAÇÃO COMERCIAL?</b></p>
            </div> 
        
            <form className="form" onSubmit={sendEmail}>
                <div className='form-floating'>
                    <input 
                        className="form-control mt-2"
                        type="text"
                        placeholder="Digite seu nome"
                        onChange={(e) => setNome(e.target.value)}
                        value={nome}
                    />
                    <label htmlFor='floatingInput'>Nome Completo</label>
                </div>
                <div className='form-floating'>    
                    <input 
                        className="form-control mt-2"
                        type="text"
                        placeholder="Digite seu email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <label htmlFor='floatingInput'>Endereço de E-Mail</label>
                </div>
                <div className='form-floating'>
                    <textarea 
                        className="form-control textarea mt-2"
                        placeholder="Digite sua mensagem..."
                        onChange={(e) => setMensagem(e.target.value)}
                        value={mensagem}
                    />
                    <label htmlFor='floatingInput'>Seu Comentário ou Mensagem</label>
                </div>
            
                    <input className="w-100 btn btn-lg btn-dark mt-2" type="submit" value="Enviar" />
            </form>
        
            </div>
        </section>
      );
    }

export default FormMail;