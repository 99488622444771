import React from 'react';
import Header from './Components/header';
import Banner from './Components/banner';
import Sobre from './Components/sobre';
import Depoimento from './Components/depoimento';
import Escolha from './Components/escolha';
import NossosClientes from './Components/clientes';
import Orcamento from './Components/orcamento';
import Produtos from './Components/produtos';
import Suporte from './Components/suporte';
import Reset from './Components/reset';
import Contato from './Components/contato';
import FormMail from './Components/formmail';
import Footer from './Components/footer';

function Site(){
  return (
    <>
      <Header />
      <Banner />
      <Sobre />
      <Depoimento />
      <Escolha />
      <NossosClientes />
      <Orcamento />
      <Produtos />
      <Suporte />
      <Reset />
      <Contato />
      <FormMail />
      <Footer />
    </>
  );
}

export default Site;