import React from "react";
import MicroLoja from '../assets/prod-micro-loja.jpg'
import MicroLoja1 from '../assets/micro_loja_01.jpg'
import MicroFarma from '../assets/prod-micro-farma.jpg'
import MicroFarma1 from '../assets/micro_farma_01.jpg'
import MicroCheff from '../assets/prod-micro-cheff.jpg'
import MicroCheff1 from '../assets/micro_cheff_01.jpg'
import NotaSimples from '../assets/prod-nota-simples.jpg'
import NotaSimples1 from '../assets/nota_simples_01.jpg'
import ImgLoja from '../assets/microloja.png'
import ImgFarma from '../assets/microfarma.png'
import ImgCheff from '../assets/microcheff.png'

function Produtos(){
  return (
    <section id='produtos'>

      <div className='container'>
        <div className='text-center'>
          <h3>CONHEÇA OS NOSSOS PRODUTOS</h3>
        </div>
        <div className="container text-center mt-5">
          <div className="row align-items-start">
            <div className="col">      
              <a data-bs-toggle="modal" data-bs-target="#popuploja">
                <img src={MicroLoja} width="250px" alt="Produto Micro Loja" />
              </a>
            </div>
            <div className="col">
              <a data-bs-toggle="modal" data-bs-target="#popupfarma">
                <img src={MicroFarma} width="250px" alt="Produto Micro Farma" />
              </a>
            </div>
            <div className="col">
              <a data-bs-toggle="modal" data-bs-target="#popupcheff">
                <img src={MicroCheff} width="250px" alt="Produto Micro Cheff" />
              </a>
            </div>
            <div className="col">
              <a data-bs-toggle="modal" data-bs-target="#popupnsimples">
                <img src={NotaSimples} width="250px" alt="Produto Nota Simples" />
              </a>
            </div>
          </div>
          <div className="mt-3"><p>Clique em uma das imagens para saber mais.</p></div>
        </div>
       
      </div>

      {/* POPUP Micro Loja */}
      <div className="modal fade modal-xl" id="popuploja" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-6 mt-1 mb-1" id="staticBackdropLabel">APRESENTAMOS O MICRO-LOJA</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <h6>A MELHOR SOLUÇÃO PARA AUTOMAÇÃO COMERCIAL DE <b>SUPERMERCADOS E LOJAS EM GERAL!</b></h6>
                <br />
              </div>
              <div className="modal-space-top-5">
                <div className="container">
                  <div className="row">
                    <div className="col-3">
                      <img src={ImgLoja} height="300px" alt="Imagem chamada Micro-Loja" />
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-8">
                      <p className="fonte-10 mb-0">SOFTWARE GERENCIAL DE SUPERMERCADOS E LOJAS DE VAREJO EM GERAL</p>
                      <p className="fonte-14">PERFEITO PARA A SUA <font color="#297ac1">LOJA DE VAREJO OU SUPERMERCADO</font></p>
                      <div className="container">

                        <div className="row align-items-start">
                          <div className="col-1">
                            <i className="fa-solid fa-thumbs-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mt-2">COMPLETAMENTE SIMPLES</h6>
                            <p className="fonte-10">Software totalmente em Português, possui interface simples e perfeita para todos os níveis de usuário. Permite o controle total de estoque, contas a pagar e receber, emissão de qualquer modelo de Nota Fiscal, controle bancário multicontas, gerenciamento de impostos, introdução ao CRM, entre muitas outras funções.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-life-ring fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">SUPORTE TÉCNICO TOTAL</h6>
                            <p className="fonte-10">Além de todos os recursos técnicos para a melhor automação comercial de sua loja de varejo, o Micro-Loja conta ainda com um suporte técnico total para sua comodidade e segurança.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-jet-fighter-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">RÁPIDO E ROBUSTO</h6>
                            <p className="fonte-10">Com um processamento extremamente rápido e eficiente, o Micro-Loja é também um software robusto e que oferece total confiança ao usuário.</p>
                          </div>
                        </div>
                      </div>   
                    </div>
                  </div>  
                </div>
                <div className="mt-4 text-center">
                  <h4 className="texto-h4"><font color="#297ac1">SAIBA MAIS SOBRE O MICRO-LOJA</font></h4>
                  <p><b>O melhor software de automação comercial do mercado para supermercados e lojas de varejo!</b></p>
          
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-7">
                      <p className="fonte-10 mt-3 mb-1">UTILIZADO POR MAIS DE 300 CLIENTES EM TODO O BRASIL</p>
                      <h4 className="texto-h4">SOLUÇÕES COMPLETAS: <b><font color="#297ac1">MICRO-LOJA</font></b></h4>
                      <p className="fonte-10">O <b>Micro-Loja</b> é nossa solução para automação comercial total de supermercados  e lojas de varejo em geral. Com diversas aplicações rodando no Brasil, o Micro-Loja abrange todos os tipos de lojas e comércios de varejo, como supermercados, lojas de roupas, calçados, alimentos, papelarias, informática e muitas outras.
Possui diversos recursos técnicos e integrações com todos os tipos de hardware disponíveis.
Com operação simples, rápida e totalmente dinâmica, o Micro-Loja proporciona relatórios gerenciais completos, integração total com sistema bancário, ICMS e muito mais.
O Micro-Loja oferece também a função de CRM, de modo que você possa realizar ações personalizadas para seus melhores clientes ou clientes em potencial.</p>
                      <img src={MicroLoja1} alt="Produto Micro-Loja" />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4 mt-3">
                      <h6 className="fonte-14-h6 mb-0">INTEGRAÇÃO TOTAL COM SEU HARDWARE</h6>
                      <p className="fonte-11 mb-0">100% em Português e editor com acentuação</p>
                      <p className="fonte-11 mb-0">Leitura de Código de Barras</p>
                      <p className="fonte-11 mb-0">Emissão de SAT, NFC-e e NF-e</p>
                      <p className="fonte-11 mb-0">Terminal de Vendas On-Line e Off-Line</p>
                      <p className="fonte-11 mb-0">Etiquetas Personalizadas (gôndolas e prateleiras)</p>
                      <p className="fonte-11 mb-0">Leitura de Balança Eletrônica (diversas marcas)</p>
                      <p className="fonte-11 mb-0">Relatórios em vídeo e impressos</p>
                      <p className="fonte-11 mb-0">Funciona em qualquer tipo de rede</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">RECURSOS E FUNÇÕES</h6>
                      <p className="fonte-11 mb-0">Gerador de Relatórios</p>
                      <p className="fonte-11 mb-0">Módulo de Ordem de Serviço</p>
                      <p className="fonte-11 mb-0">Controle de Estoque</p>
                      <p className="fonte-11 mb-0">Contas a Pagar e Receber</p>
                      <p className="fonte-11 mb-0">Emissão de Notas Fiscais (qualquer modelo)</p>
                      <p className="fonte-11 mb-0">Formação do preço de venda</p>
                      <p className="fonte-11 mb-0">Cotação de preços de compra</p>
                      <p className="fonte-11 mb-0">Rentabilidade de produto a produto</p>
                      <p className="fonte-11 mb-0">Disk-Entrega (Televendas)</p>
                      <p className="fonte-11 mb-0">Controle de Caixa Administrativo</p>
                      <p className="fonte-11 mb-0">Controle de Vendas Diário e Mensal</p>
                      <p className="fonte-11 mb-0">Controle de Consignação</p>
                      <p className="fonte-11 mb-0">Proteção por senhas em cada opção de menu</p>
                      <p className="fonte-11 mb-0">Desconto de item por item ou no total da venda</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">INTEGRAÇÃO COM SISTEMA BANCÁRIO</h6>
                      <p className="fonte-11 mb-0">Controle Bancário (multi-contas)</p>
                      <p className="fonte-11 mb-0">TEF – Transferência Eletrônica de Fundos</p>
                      <p className="fonte-11 mb-0">Controle de cheques pré-datados e cartões</p>
                      <p className="fonte-11 mb-0">Controle de vendas à vista, convênio e crédito</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">CRM (RELACIONAMENTO COM O CLIENTE)</h6>
                      <p className="fonte-11 mb-0">Parabeniza o cliente no dia de seu aniversário</p>
                      <p className="fonte-11 mb-0">Cadastro de emitentes de cheques sem fundos</p>
                      <p className="fonte-11 mb-0">Agenda de compromissos, telefones e endereços</p>
                    </div>
                  </div>
                </div>

              </div>              
            </div>
          </div>
        </div>
      </div>      

      {/* POPUP Micro Farma */}
      <div className="modal fade modal-xl" id="popupfarma" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-6 mt-1 mb-1" id="staticBackdropLabel">APRESENTAMOS O MICRO-FARMA</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <h6>A MELHOR SOLUÇÃO PARA AUTOMAÇÃO COMERCIAL DE <b>FARMACIAS E DROGARIAS!</b></h6>
                <br />
              </div>
              <div className="modal-space-top-5">
                <div className="container">
                  <div className="row">
                    <div className="col-3">
                      <img src={ImgFarma} height="300px" alt="Imagem chamada Micro-Loja" />
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-8">
                      <p className="fonte-10 mb-0">SOFTWARE GERENCIAL DE FARMÁCIAS E DROGARIAS</p>
                      <p className="fonte-14">PERFEITO PARA A SUA <font color="#297ac1">FARMÁCIA</font></p>
                      <div className="container">

                        <div className="row align-items-start">
                          <div className="col-1">
                            <i className="fa-solid fa-thumbs-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mt-2">COMPLETAMENTE SIMPLES</h6>
                            <p className="fonte-10">Software totalmente em Português, possui interface simples e perfeita para todos os níveis de usuário. Permite o controle total de estoque, contas a pagar e receber, emissão de qualquer modelo de Nota Fiscal, controle bancário multicontas, gerenciamento de impostos, introdução ao CRM, entre muitas outras funções.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-life-ring fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">SUPORTE TÉCNICO TOTAL</h6>
                            <p className="fonte-10">Além de todos os recursos técnicos para a melhor automação comercial de sua loja de varejo, o Micro-Loja conta ainda com um suporte técnico total para sua comodidade e segurança.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-jet-fighter-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">RÁPIDO E ROBUSTO</h6>
                            <p className="fonte-10">Com um processamento extremamente rápido e eficiente, o Micro-Loja é também um software robusto e que oferece total confiança ao usuário.</p>
                          </div>
                        </div>
                      </div>   
                    </div>
                  </div>  
                </div>
                <div className="mt-4 text-center">
                  <h4 className="texto-h4"><font color="#297ac1">SAIBA MAIS SOBRE O MICRO-FARMA</font></h4>
                  <p><b>O melhor software de automação comercial para farmácias e drogarias!</b></p>
          
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-7">
                      <p className="fonte-10 mt-3 mb-1">UTILIZADO POR MAIS DE 300 CLIENTES EM TODO O BRASIL</p>
                      <h4 className="texto-h4">SOLUÇÕES COMPLETAS: <b><font color="#297ac1">MICRO-FARMA</font></b></h4>
                      <p className="fonte-10">O <b>Micro-Farma</b> é nossa solução para automação comercial total de farmácias e drogarias. Com diversas aplicações rodando no Brasil, o Micro-Farma abrange todos os tipos de farmácias e drogarias de pequeno, médio e grande porte.
Possui diversos recursos técnicos e integrações com todos os tipos de hardware disponíveis.
Com operação simples, rápida e totalmente dinâmica, o Micro-Farma proporciona relatórios gerenciais completos, integração total com sistema bancário, ICMS e muito mais.
O Micro-Farma oferece também a função de CRM, de modo que você possa realizar ações personalizadas para seus melhores clientes ou clientes em potencial.</p>
                      <img src={MicroFarma1} alt="Produto Micro-Farma" />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4 mt-3">
                      <h6 className="fonte-14-h6 mb-0">INTEGRAÇÃO TOTAL COM SEU HARDWARE</h6>
                      <p className="fonte-11 mb-0">100% em Português e editor com acentuação</p>
                      <p className="fonte-11 mb-0">Leitura de Código de Barras</p>
                      <p className="fonte-11 mb-0">Emissão de SAT, NFC-e e NF-e</p>
                      <p className="fonte-11 mb-0">Terminal de Vendas On-Line e Off-Line</p>
                      <p className="fonte-11 mb-0">Etiquetas Personalizadas (gôndolas e prateleiras)</p>
                      <p className="fonte-11 mb-0">Relatórios em vídeo e impressos</p>
                      <p className="fonte-11 mb-0">Funciona em qualquer tipo de rede</p>
                      <p className="fonte-11 mb-0"> Comunicação de lojas via rede ou internet</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">RECURSOS E FUNÇÕES</h6>
                      <h6 className="fonte-14-h6 mb-0">INTEGRAÇÃO COM TODOS O PBMs</h6>
                      <p className="fonte-11 mb-0">e-Pharma</p>
                      <p className="fonte-11 mb-0">Pharmalink</p>
                      <p className="fonte-11 mb-0">Portal da Drogaria (Novartis, TrnCentre)</p>
                      <p className="fonte-11 mb-0">Vidalink</p>
                      <p className="fonte-11 mb-0">Orizon-Farma</p>
                      <p className="fonte-11 mb-0">Funcional Card</p>
                      <p className="fonte-11 mb-0">Golden Farma</p>
                      <p className="fonte-11 mb-0">Vale Mais Saúde, Prev-saúde e outros.</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0"><b>FARMACIA POPULAR</b> direto com o Datasus</h6>
                      <p className="fonte-11 mb-0">Vendas, Pré-vendas e Orçamentos</p>
                      <p className="fonte-11 mb-0">Controle de vendas à vista, convênio e crédito</p>
                      <p className="fonte-11 mb-0">Contas a Pagar e Receber</p>
                      <p className="fonte-11 mb-0">Emissão de Nota Fiscal Eletrônica, cupons fiscais (NFC-e e SAT-CFe)</p>
                      <p className="fonte-11 mb-0">Controle de estoque completo</p>
                      <p className="fonte-11 mb-0">Entrada de mercadorias manual e por XML</p>
                      <p className="fonte-11 mb-0">Rentabilidade produto a produto, por seção e geral</p>
                      <p className="fonte-11 mb-0">Disk-Entrega (Televendas)</p>
                      <p className="fonte-11 mb-0">Controle de Caixa Administrativo</p>
                      <p className="fonte-11 mb-0">Controle de Vendas Diário e Mensal</p>
                      <p className="fonte-11 mb-0">Exporta os relatórios para EXCEL, WORD e PDF</p>
                      <p className="fonte-11 mb-0">Proteção por senhas em cada opção de menu</p>
                      <p className="fonte-11 mb-0">Desconto de item por item ou no total da venda</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">INTEGRAÇÃO COM SISTEMA BANCÁRIO</h6>
                      <p className="fonte-11 mb-0">Controle Bancário (multi-contas)</p>
                      <p className="fonte-11 mb-0">TEF – Transferência Eletrônica de Fundos</p>
                      <p className="fonte-11 mb-0">Controle de cheques pré-datados e cartões</p>
                      <p className="fonte-11 mb-0">Controle de vendas à vista, convênio e crédito</p>
                      <p className="fonte-11 mb-0">Atualizações de preços</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">CRM (RELACIONAMENTO COM O CLIENTE)</h6>
                      <p className="fonte-11 mb-0">Parabeniza o cliente no dia de seu aniversário</p>
                      <p className="fonte-11 mb-0">Cadastro de emitentes de cheques sem fundos</p>
                      <p className="fonte-11 mb-0">Agenda de compromissos, telefones e endereços</p>
                    </div>
                  </div>
                </div>

              </div>              
            </div>
          </div>
        </div>
      </div>      

      {/* POPUP Micro Cheff */}
      <div className="modal fade modal-xl" id="popupcheff" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-6 mt-1 mb-1" id="staticBackdropLabel">APRESENTAMOS O MICRO-CHEFF</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <h6>A MELHOR SOLUÇÃO PARA AUTOMAÇÃO COMERCIAL DE <b>BARES E RESTAURANTES!</b></h6>
                <br />
              </div>
              <div className="modal-space-top-5">
                <div className="container">
                  <div className="row">
                    <div className="col-3">
                      <img src={ImgCheff} height="300px" alt="Imagem chamada Micro-Loja" />
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-8">
                      <p className="fonte-10 mb-0">SOFTWARE GERENCIAL DE SUPERMERCADOS E LOJAS DE VAREJO EM GERAL</p>
                      <p className="fonte-14">PERFEITO PARA O SEU <font color="#297ac1">BAR OU RESTAURANTE</font></p>
                      <div className="container">

                        <div className="row align-items-start">
                          <div className="col-1">
                            <i className="fa-solid fa-thumbs-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mt-2">COMPLETAMENTE SIMPLES</h6>
                            <p className="fonte-10">Software totalmente em Português, possui interface simples e perfeita para todos os níveis de usuário. Permite o controle total de estoque, contas a pagar e receber, emissão de qualquer modelo de Nota Fiscal, controle bancário multicontas, gerenciamento de impostos, introdução ao CRM, entre muitas outras funções.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-life-ring fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">SUPORTE TÉCNICO TOTAL</h6>
                            <p className="fonte-10">Além de todos os recursos técnicos para a melhor automação comercial de sua loja de varejo, o Micro-Loja conta ainda com um suporte técnico total para sua comodidade e segurança.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-jet-fighter-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">RÁPIDO E ROBUSTO</h6>
                            <p className="fonte-10">Com um processamento extremamente rápido e eficiente, o Micro-Loja é também um software robusto e que oferece total confiança ao usuário.</p>
                          </div>
                        </div>
                      </div>   
                    </div>
                  </div>  
                </div>
                <div className="mt-4 text-center">
                  <h4 className="texto-h4"><font color="#297ac1">SAIBA MAIS SOBRE O MICRO-CHEFF</font></h4>
                  <p><b>O melhor software de automação comercial para bares e restaurantes!</b></p>
          
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-7">
                      <p className="fonte-10 mt-3 mb-1">UTILIZADO POR MAIS DE 300 CLIENTES EM TODO O BRASIL</p>
                      <h4 className="texto-h4">SOLUÇÕES COMPLETAS: <b><font color="#297ac1">MICRO-CHEFF</font></b></h4>
                      <p className="fonte-10">O <b>Micro-Cheff</b> é nossa solução para automação comercial total de bares e restaurantes. Com diversas aplicações rodando no Brasil, o Micro-Cheff abrange todos os tipos de restaurantes, lanchonetes, bares, casas de suco, sorveterias, entre outros estabelecimentos do gênero, de pequeno, médio e grande porte.
Possui diversos recursos técnicos e integrações com todos os tipos de hardware disponíveis.
Com operação simples, rápida e totalmente dinâmica, o Micro-Cheff proporciona relatórios gerenciais completos, ICMS e muito mais.
O Micro-Cheff oferece também a possibilidade de controlar até 999 mesas ou cartões de consumo ao mesmo tempo.</p>
                      <img src={MicroCheff1} alt="Produto Micro-Loja" />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4 mt-3">
                      <h6 className="fonte-14-h6 mb-0">INTEGRAÇÃO TOTAL COM SEU HARDWARE</h6>
                      <p className="fonte-11 mb-0">100% em Português e editor com acentuação</p>
                      <p className="fonte-11 mb-0">Leitura de Código de Barras</p>
                      <p className="fonte-11 mb-0">Emissão de SAT, NFC-e e NF-e</p>
                      <p className="fonte-11 mb-0">Terminal de Vendas On-Line e Off-Line</p>
                      <p className="fonte-11 mb-0">Etiquetas Personalizadas (gôndolas e prateleiras)</p>
                      <p className="fonte-11 mb-0">Leitura de Balança Eletrônica (diversas marcas)</p>
                      <p className="fonte-11 mb-0">Relatórios em vídeo e impressos</p>
                      <p className="fonte-11 mb-0">Funciona em qualquer tipo de rede</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">RECURSOS E FUNÇÕES</h6>
                      <p className="fonte-11 mb-0">Gerador de Relatórios</p>
                      <p className="fonte-11 mb-0">Controle de Estoque</p>
                      <p className="fonte-11 mb-0">Contas a Pagar e Receber</p>
                      <p className="fonte-11 mb-0">Emissão de Nota Fiscal Eletrônica e Cupons Fiscais (NFC-e e SAT-CFe)</p>
                      <p className="fonte-11 mb-0">Controle de entregas por região</p>
                      <p className="fonte-11 mb-0">Controle de de até 999 mesas ou cartões de consumo</p>
                      <p className="fonte-11 mb-0">Rentabilidade de produto a produto</p>
                      <p className="fonte-11 mb-0">Disk-Entrega (Televendas)</p>
                      <p className="fonte-11 mb-0">Controle de Caixa Administrativo</p>
                      <p className="fonte-11 mb-0">Controle de Vendas Diário e Mensal</p>
                      <p className="fonte-11 mb-0">Proteção por senhas em cada opção de menu</p>
                      <p className="fonte-11 mb-0">Desconto de item por item ou no total da venda</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">INTEGRAÇÃO COM SISTEMA BANCÁRIO</h6>
                      <p className="fonte-11 mb-0">Controle Bancário (multi-contas)</p>
                      <p className="fonte-11 mb-0">TEF – Transferência Eletrônica de Fundos</p>
                      <p className="fonte-11 mb-0">Controle de cheques pré-datados e cartões</p>
                      <p className="fonte-11 mb-0">Controle de vendas à vista, convênio e crédito</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">CRM (RELACIONAMENTO COM O CLIENTE)</h6>
                      <p className="fonte-11 mb-0">Parabeniza o cliente no dia de seu aniversário</p>
                      <p className="fonte-11 mb-0">Cadastro de emitentes de cheques sem fundos</p>
                      <p className="fonte-11 mb-0">Agenda de compromissos, telefones e endereços</p>
                    </div>
                  </div>
                </div>

              </div>              
            </div>
          </div>
        </div>
      </div>      
  
      {/* POPUP Nota Simples */}
      <div className="modal fade modal-xl" id="popupnsimples" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-6 mt-1 mb-1" id="staticBackdropLabel">APRESENTAMOS O NOTA SIMPLES</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <h6>SIMPLES DE INSTALAR, <b>SIMPLES DE USAR.</b></h6>
                <br />
              </div>
              <div className="modal-space-top-5">
                <div className="container">
                  <div className="row">
                    <div className="col-3">
                      <img src={ImgLoja} height="300px" alt="Imagem chamada Micro-Loja" />
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-8">
                      <p className="fonte-10 mb-0">SOFTWARE EMISSOR de NF-e - NFC-e e SAT</p>
                      <p className="fonte-14">SIMPLES DE INSTALAR, <font color="#297ac1">SIMPLES DE USAR!</font></p>
                      <div className="container">

                        <div className="row align-items-start">
                          <div className="col-1">
                            <i className="fa-solid fa-thumbs-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mt-2">COMPLETAMENTE SIMPLES</h6>
                            <p className="fonte-10">Software totalmente em Português, possui interface simples e perfeita para todos os níveis de usuário. Permite o controle total de estoque, contas a pagar e receber, emissão de qualquer modelo de Nota Fiscal, controle bancário multicontas, gerenciamento de impostos, introdução ao CRM, entre muitas outras funções.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-life-ring fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">SUPORTE TÉCNICO TOTAL</h6>
                            <p className="fonte-10">Além de todos os recursos técnicos para a melhor automação comercial de sua loja de varejo, o Micro-Loja conta ainda com um suporte técnico total para sua comodidade e segurança.</p>
                          </div>
                        </div>

                        <div className="row align-items-start mt-3">
                          <div className="col-1">
                            <i className="fa-solid fa-jet-fighter-up fa-3x"></i>
                          </div>
                          <div className="col-11">
                            <h6 className="fonte-14-h6 mb-0">RÁPIDO E ROBUSTO</h6>
                            <p className="fonte-10">Com um processamento extremamente rápido e eficiente, o Micro-Loja é também um software robusto e que oferece total confiança ao usuário.</p>
                          </div>
                        </div>
                      </div>   
                    </div>
                  </div>  
                </div>
                <div className="mt-4 text-center">
                  <h4 className="texto-h4"><font color="#297ac1">SAIBA MAIS SOBRE O NOTA SIMPLES</font></h4>
                  <p><b>Faça suas Notas Fiscais, Cupons fiscais (NFC-e ou SAT) do modo mais SIMPLES que existe.</b></p>
          
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-7">
                      <p className="fonte-10 mt-3 mb-1">UTILIZADO POR MAIS DE 300 CLIENTES EM TODO O BRASIL</p>
                      <h4 className="texto-h4">SOLUÇÕES COMPLETAS: <b><font color="#297ac1">MICRO-LOJA</font></b></h4>
                      <p className="fonte-10">O <b>NOTA SIMPLES</b> é nossa solução para emissão de documentos fiscais, como Nota Fiscal Eletrônica, Cupom fiscal nos formatos NFC- e SAT-CFe. Com diversas aplicações rodando no Brasil, o Nota Simples abrange todos os tipos de lojas e comércios de varejo, como supermercados, lojas de roupas, calçados, alimentos, papelarias, informática e muitas outras.
Possui diversos recursos técnicos e integrações com todos os tipos de hardware disponíveis.
Com operação simples, rápida e totalmente dinâmica, o Nota Simples proporciona facilidades para emissão de documentos fiscais, relatórios gerenciais para controle de notas emitidas.
O Nota Simples foi feito para quem quer um sistema simples e de baixo custo para emissão de Notas e Cupons Fiscais.</p>
                      <img src={NotaSimples1} alt="Produto Nota Simples" />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4 mt-3">
                      <h6 className="fonte-14-h6 mb-0">INTEGRAÇÃO TOTAL COM SEU HARDWARE</h6>
                      <p className="fonte-11 mb-0">100% em Português e editor com acentuação</p>
                      <p className="fonte-11 mb-0">Leitura de Código de Barras</p>
                      <p className="fonte-11 mb-0">NF-e de venda, devolução e remessa para industrialização</p>
                      <p className="fonte-11 mb-0">Emissão de Cupons Fiscais NFC-e e SAT-CFe</p>
                      <p className="fonte-11 mb-0">Comunicação com Balança Eletrônica de checkout (diversas marcas)</p>
                      <p className="fonte-11 mb-0">Relatórios em vídeo e impressos</p>
                      <p className="fonte-11 mb-0">Funciona em qualquer tipo de rede</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">RECURSOS E FUNÇÕES</h6>
                      <p className="fonte-11 mb-0">Gerador de Relatórios</p>
                      <p className="fonte-11 mb-0">Emissão de Notas Fiscais (qualquer modelo)</p>
                      <p className="fonte-11 mb-0">Controle simplificado de caixa</p>
                      <p className="fonte-11 mb-0">Controle notas emitidas Diário e Mensal</p>
                      <p className="fonte-11 mb-0">Proteção por senhas em cada opção de menu</p>
                      <p className="fonte-11 mb-0">Desconto de item por item ou no total da venda</p>

                      <h6 className="fonte-14-h6 mt-4 mb-0">CRM (RELACIONAMENTO COM O CLIENTE)</h6>
                      <p className="fonte-11 mb-0">Agenda de compromissos, telefones e endereços</p>
                    </div>
                  </div>
                </div>

              </div>              
            </div>
          </div>
        </div>
      </div>      
   

    </section>
  )
}

export default Produtos;