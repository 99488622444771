import React from "react";

function Escolha(){
  return (
    <section id='escolha' className='mt-5'>

      <div className='container'>
        <h3 className='text-center'>POR QUE ESCOLHER A MICRO-F?</h3>
        <p>Temos soluções completas e perfeitas para automação comercial de seu Supermercado, Loja, Drogaria, Restaurante e comércio varejista em geral!</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5 className="mt-2 text-center color-title-escolha"><i className="fas fa-shopping-cart fa-3x"></i></h5>
            <h5 className="mt-2 text-center color-title-escolha">MICRO-LOJA</h5>
            <p className="mt-2 text-center">Software de automação comercial para todos os tipos de lojas de varejos. De pequeno a grande porte, para todos os segmentos.</p>
          </div>

          <div className="col-4">
            <h5 className="mt-2 text-center color-title-escolha"><i className="fas fa-briefcase-medical fa-3x"></i></h5>
            <h5 className="mt-2 text-center color-title-escolha">MICRO-FARMA</h5>
            <p className="mt-2 text-center">Software de automação comercial desenvolvido para todos os tipos e portes de farmácias e drogarias.</p>
          </div>
          
          <div className="col-4">
            <h5 className="mt-2 text-center color-title-escolha"><i className="fas fa-utensils fa-3x"></i></h5>
            <h5 className="mt-2 text-center color-title-escolha">MICRO-CHEFF</h5>
            <p className="mt-2 text-center">Software de automação comercial para restaurantes, bares, lanchonetes e todos os tipos de estabelecimentos do gênero.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Escolha;