import React from "react";
import Logo from '../assets/logo.png';
import BannerWhats from '../assets/mini-banner-whats.png'
import BannerPhone from '../assets/mini-banner-phone.png'

function Header(){
    return (
      <nav className="navbar sticky-top navbar-expand-md navbar-dark">

        <div className="container">
          <a className="navbar-brand ms-3" href="#">
            <img src={Logo} alt="Logo Micro-F" height="60" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse justify-content-center link-menu" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#">HOME</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#sobre">SOBRE</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#produtos">PRODUTOS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#suporte">SUPORTE</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#reset">RESET</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contato">CONTATO</a>
              </li>
            </ul>
          </div>
          <div className="d-flex flex-column justify-con~tent-end">
            <div className="p-0">
              <a href="#">
                <img src={BannerWhats} alt="Bootstrap" height="35" />
              </a>
            </div>
            <div className="p-0">
              <a href="#">
                <img src={BannerPhone} alt="Bootstrap" height="35" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
}

export default Header;