import React from 'react';

function Suporte(){
  return (
    <section id='suporte' className='mt-5'>
        <div className='container'>
            <div className='text-center'>
                <h3>SUPORTE REMOTO</h3>
            </div>
            <div className='row align-items-start'>
                <div className='mt-3'>
                    <p>Através de um programa de acesso remoto (mfconecta.exe), nossa equipe de Suporte Técnico poderá  auxiliá-lo na utilização e esclarecimento de dúvidas.</p>
                </div>
                <div className='col-2'></div>
                <div className='col-8'>
                    <p className='mt-3'><b>Siga o procedimento abaixo para acionar o suporte remoto:</b></p>
                    <p className='fonte-10 mb-0'>Faça o download do programa de suporte (mfconecta.exe)</p>
                    <p className='fonte-10 mb-0'>Execute o mfconecta.exe</p>
                    <p className='fonte-10 mb-0'>Sempre que aparecer uma mensagem solicitando sua autorização para executar o programa clique em “Executar” (ou em “Sim”)</p>
                    <p className='fonte-10 mb-3'>Quando aparecer uma tela com a identificação da sua estação, clique em Obter Ajuda e em seguida nos balões de bate-papo</p>
                    <div className="container text-center mt-4">
                        <p><b>Faça o download aqui:</b></p>
                        <div className='row mt-2'>
                            <div className='col-4'>
                                <a href="https://micro-f.com.br/downloads/anydesk.exe" target="_blank" className="btn btn-primary btn-lg">ANYDESK</a>
                            </div>
                            <div className='col-4'>
                                <a href="https://micro-f.com.br/downloads/mfconecta.exe" target="_blank" className="btn btn-primary btn-lg">MFCONECTA</a>
                            </div>
                            <div className='col-4'>
                                <a href="https://micro-f.com.br/downloads/mfhelpdesk.exe" target="_blank" className="btn btn-primary btn-lg">MFHELPDESK</a>
                            </div>                            
                        </div>
                    </div>  
                    <p className='mt-3'><b>Importante:</b></p>
                    <p className='fonte-10 mb-0'>Para que o suporte possa ser realizado com sucesso, seu desktop precisará ter uma conexão com internet de boa qualidade.</p>
                    <p className='fonte-10 mb-5'>Em algumas instalações/configurações de rede o firewall ou antivírus instalado poderá interferir no funcionamento do nosso programa de suporte remoto.</p>
                </div>
                <div className='col-2'></div>
            </div>
        </div>
    </section>
  )
}

export default Suporte;