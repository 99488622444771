import React from "react";

function Sobre(){
  return (
    <section id='sobre'>

      <div className='container mt-5'>
        <h3 className='text-center'>SEU NEGÓCIO PODE TER O MELHOR EM AUTOMAÇÃO COMERCIAL</h3>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5 className="mt-5 text-center color-title-sobre">DESDE 1997</h5>
            <p className="mt-2 text-center">A Micro-F – Automação Comercial foi criada em 1997 visando atender ao mercado de soluções corporativas que envolvessem tecnologias emergentes na área de informática. Desde então tem apresentando alto nível de crescimento, consolidando a estratégia, atuação e atingindo novos segmentos de mercado.</p>
          </div>

          <div className="col-4">
            <h5 className="mt-5 text-center color-title-sobre">MISSÃO</h5>
            <p className="mt-2 text-center">A missão da Micro-F é o atendimento com excelência a seus clientes, buscando permanente inovação tecnológica com pronto oferecimento de soluções completas em automação comercial, com o melhor custo benefício.</p>
          </div>
          
          <div className="col-4">
            <h5 className="mt-5 text-center color-title-sobre">ATUAÇÃO</h5>
            <p className="mt-2 text-center">Sediada em São Bernardo do Campo-SP com escritório em Goiânia-GO, a Micro-F possui ampla capacidade de atender a todos os estados brasileiros. A Micro-F proporciona a seus clientes um atendimento totalmente diferenciado, o que tem contribuído para o crescimento em cada localidade e em cada nova aplicação.</p>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Sobre;