import React from "react";
import Logo from '../assets/logo.png';
import ViaWeb from '../assets/viaweb-logo.png'

function Footer(){
  return (
    <section id='footer'>

        <div className="container text-center mt-3">
          <div className="row align-items-start">
            <div className='col-3 text-center'>
              <img src={Logo} alt='Logo Micro-F' />
              <p className='mt-3 fonte-10'>Micro-F Automação Comercial fornece sistemas inteligentes e soluções para lojas de varejo em geral, drogarias, restaurantes e supermercados.</p>
            </div>
            <div className='col-3'>
              <h3 className='fonte-20-footer'>MICRO-LOJA</h3>
              <p className='mt-3 fonte-10'>Software Gerencial de Lojas de Varejo em Geral. É perfeito para o gerenciamento de qualquer tipo de Loja de Varejo.</p>
            </div>
            <div className='col-3'>
              <h3 className='fonte-20-footer'>MICRO-FARMA</h3>
              <p className='mt-3 fonte-10'>Software Gerencial de Drogarias e Farmácias. Com mais de 25 anos de mercado, o Micro-Farma fornece gerenciamento completo e total segurança à centenas de drogarias e farmácias de todo o país.</p>
            </div>
            <div className='col-3'>
              <h3 className='fonte-20-footer'>MICRO-CHEFF</h3>
              <p className='mt-3 fonte-10'>O Micro-Cheff é o mais completo software para gerenciamento de restaurantes em geral.</p>
            </div>
          </div>
        </div>        
        <div className='container-fluid'>
          <div className='text-center'>
            <h6 className='fonte-11'>Criado por</h6>
            <a href="http://viawebsolucoes.com.br" target="_blank" rel="noreferrer">
              <img src={ViaWeb} width='100' alt='Logo ViaWeb' />
            </a>
            <p className='font-10'>usando <i className="fa-solid fa-heart"></i> ReactJS</p>
            <p>© copyright 1997-2023 - Micro-F Automação Comercial. - Todos os direitos reservados.</p>
          </div>
        </div>

    </section>
  )
}

export default Footer;