import React from "react";
import Cliente1 from '../assets/cliente1.png'
import Cliente2 from '../assets/cliente2.png'
import Cliente3 from '../assets/cliente3.png'
import Cliente4 from '../assets/cliente4.png'
import Cliente5 from '../assets/cliente5.png'
import Cliente6 from '../assets/cliente6.png'
import Cliente7 from '../assets/cliente7.png'

function NossosClientes(){
  return (
    <section id='clientes'>

      <div className='container'>
        <div className='text-center'>
          <h5>CONHEÇA ALGUNS DE</h5>
          <h3>NOSSOS CLIENTES</h3>
        </div>
        <div className="container text-center mt-5">
          <div className="row align-items-start">
            <div className="col">
              <img src={Cliente1} width="130px" alt="Logo cliente 1" />
            </div>
            <div className="col">
              <img src={Cliente2} width="130px" alt="Logo cliente 2" />
            </div>
            <div className="col">
              <img src={Cliente3} width="130px" alt="Logo cliente 3" />
            </div>
            <div className="col">
              <img src={Cliente4} width="130px" alt="Logo cliente 4" />
            </div>
            <div className="col">
              <img src={Cliente5} width="130px" alt="Logo cliente 5" />
            </div>
            <div className="col">
              <img src={Cliente6} width="130px" alt="Logo cliente 6" />
            </div>
            <div className="col">
              <img src={Cliente7} width="130px" alt="Logo cliente 7" />
            </div>
          </div>
        </div>        
      </div>

    </section>
  )
}

export default NossosClientes;