import React from "react";
import Foto from '../assets/fernando.png'

function Depoimento(){
  return (
    <section id='depoimento'>

      <div className='container'>
        <div className='text-center'>
          <h1><i className="fa-solid fa-comments"></i></h1>
        </div>
        <div className='text-center'>
          <p>A Micro-F é atualmente, uma das principais fabricantes de softwares específicos do país, possuindo soluções para automação comercial para Supermercados, Farmácias e Drogarias, Restaurantes e Lojas de varejo em geral, além do NOTA SIMPLES, um sistema SIMPLES de usar para emissão de documentos fiscais (NF-e, NFC-e e SAT-CFe).</p>
        </div>
        <div className="text-center">
          <img className='mt-2 mb-2' src={Foto} alt="Foto do Fernando" />
          <h6 className="m-0">LUIZ FERNANDO</h6>
          <p className="mt-0">Diretor de Vendas</p>
        </div>
      </div>

    </section>
  )
}

export default Depoimento;